declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export const pushDataLayer = (object: Record<string, unknown>): void => {
  if (typeof window === 'undefined') {
    return;
  }

  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [];
  }

  window.dataLayer.push(object);
};

interface User {
  id: string;
  firstName: string;
  lastName?: string;
  email: string;
  admin: boolean;
}

export const pushIdentifyUser = (user: User): void => {
  pushDataLayer({
    event: 'identify',
    user: {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      type: user.admin ? 'internal' : 'customer',
    },
  });
};
