/**
 * LIBS
 */
import { ReactElement } from 'react';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';

import { CacheProvider as EmotionCacheProvider } from '@emotion/react';

import { ThemeProvider as SCThemeProvider } from '@snowpak/ui';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline, Slide } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

/**
 * HELPERS
 */
import { createEmotionCache } from '@libs/emotion';
import useAuth from '@helpers/hooks/useAuth';
import { pushIdentifyUser } from '@helpers/gtm';

/**
 * THEMES
 */
import theme from '@theme';

/**
 * APOLLO
 */
import { useApollo } from '../libs/apollo';

/**
 * Layout
 */
import { getAdminLayout } from '@layouts/AdminLayout';

/**
 * TYPES
 */
import type { AppProps } from 'next/app';
import type { EmotionCache } from '@emotion/react';

export interface CustomAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MATERIAL_PRO_LICENSE_KEY);

const CustomApp = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: CustomAppProps): ReactElement => {
  const apolloClient = useApollo();
  const user = useAuth(apolloClient);
  const getLayout = Component.getLayout || getAdminLayout;

  if (!user) return null;

  pushIdentifyUser(user);

  return (
    <EmotionCacheProvider value={emotionCache}>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <meta name="snowpak-cms" content="superadmin"></meta>
      </Head>

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <MuiThemeProvider theme={theme}>
          <SCThemeProvider>
            <ApolloProvider client={apolloClient}>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                TransitionComponent={Slide}
              >
                <CssBaseline />
                {getLayout(<Component {...pageProps} />)}
              </SnackbarProvider>
            </ApolloProvider>
          </SCThemeProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </EmotionCacheProvider>
  );
};

export default CustomApp;
