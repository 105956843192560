/**
 * LIBS
 */
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import _pickBy from 'lodash/pickBy';
import _filter from 'lodash/filter';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useCallback, FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

/**
 * COMPONENTS
 */
import { ButtonWithLoading } from '@components/global/ui';
import ControlledTextField from '@components/global/ui/form/ControlledTextField';

/**
 * HELPERS
 */
import { useFindItineraryLazyQuery } from '@generated/graphql';
import useMutationErrorHandler from '@helpers/hooks/useMutationErrorHandler';

/**
 * Validations
 */
import {
  validatePublicId,
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
} from './validations';

export type TFormValues = {
  publicId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const coerceQueryValue = (val: string | string[] | undefined) => {
  if (typeof val === 'undefined') {
    return;
  }

  return typeof val === 'string' ? val : val[0];
};

const getInitialFormValuesFromQuery = (query: {
  [key: string]: string | string[] | undefined;
}): TFormValues => ({
  publicId: coerceQueryValue(query.publicId) || '',
  firstName: coerceQueryValue(query.firstName) || '',
  lastName: coerceQueryValue(query.lastName) || '',
  email: coerceQueryValue(query.email) || '',
  phone: coerceQueryValue(query.phone) || '',
});

// Note: defaultValues will not get set correctly on pages where NextJS performs
// automatic static optimization - query will be initially an empty object on first
// render
const SideSearch: FC<Record<string, never>> = () => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const initialFormValues = getInitialFormValuesFromQuery(router.query);

  const { control, handleSubmit, getValues } = useForm<TFormValues>({
    defaultValues: initialFormValues,
  });

  const queryErrorHandler = useMutationErrorHandler();
  const queryCompletedHandler = useCallback(
    data => {
      const {
        itinerary: { itinerary },
      } = data;
      if (!itinerary?.publicId) {
        enqueueSnackbar('Itinerary not found', { variant: 'warning' });
        return;
      }
      const { publicId, duplicated } = itinerary;
      const latestConfirmedId = !duplicated.length
        ? publicId
        : _filter(duplicated, dup => dup.isConfirmed === true).reduce(
            (r, a) => (r.createdAt > a.createdAt ? r : a),
            itinerary
          ).publicId;

      router.push({ pathname: `/itineraries/${latestConfirmedId}/overview` });
    },
    [enqueueSnackbar, router]
  );
  const [findItinerary, { loading }] = useFindItineraryLazyQuery({
    fetchPolicy: 'network-only',
    onError: queryErrorHandler,
    onCompleted: queryCompletedHandler,
  });

  const submitHandler: SubmitHandler<TFormValues> = useCallback(
    async formValues => {
      if (formValues.publicId) {
        findItinerary({
          variables: {
            publicId: formValues.publicId,
          },
        });
      } else {
        router.push({
          pathname: '/itineraries/search',
          query: _pickBy(formValues, item => !!item),
        });
      }
    },
    [findItinerary, router]
  );

  return (
    <Card sx={{ marginBottom: 6 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Search Itineraries
        </Typography>

        <Box sx={{ marginTop: 4 }}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ControlledTextField
                  controllerProps={{
                    control,
                    name: 'publicId',
                    rules: { validate: validatePublicId(getValues) },
                  }}
                  label="Itinerary ID"
                  type="search"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <ControlledTextField
                      controllerProps={{
                        control,
                        name: 'firstName',
                        rules: { validate: validateFirstName(getValues) },
                      }}
                      label="First Name"
                      type="search"
                      autoComplete="off"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ControlledTextField
                      controllerProps={{
                        control,
                        name: 'lastName',
                        rules: { validate: validateLastName(getValues) },
                      }}
                      label="Last Name"
                      type="search"
                      autoComplete="off"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ControlledTextField
                  controllerProps={{
                    control,
                    name: 'email',
                    rules: { validate: validateEmail(getValues) },
                  }}
                  label="Email"
                  type="search"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledTextField
                  controllerProps={{
                    control,
                    name: 'phone',
                    rules: { validate: validatePhone(getValues) },
                  }}
                  label="Phone"
                  type="search"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <ButtonWithLoading
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                >
                  Search
                </ButtonWithLoading>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SideSearch;
