import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import possibleTypes from '../../possibleTypes.json';

export const initializeCache = (): InMemoryCache =>
  new InMemoryCache({
    dataIdFromObject: object => {
      if (!object.__typename || !object.id) {
        return undefined;
      }

      if (object.__typename === 'PropertyRateAvailability') {
        return `PropertyRateAvailability:${object.id}:${object.merchantOfRecord}`;
      }

      if (object.__typename === 'PropertyRoomRate') {
        return `PropertyRoomRate:${object.id}:${object.merchantOfRecord}`;
      }

      return defaultDataIdFromObject(object);
    },
    // BREAKING CHANGE of Apollo Client 3.
    // IntrospectionFragmentMatcher has been remove and possibleTypes should be used instead.
    // https://github.com/apollographql/apollo-client/blob/master/CHANGELOG.md#inmemorycache
    possibleTypes,

    // Our top level Query type has a few "sub" Query types under product, itinerary, viewer fields.
    // Need to manually configure the merge function for them.
    // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
    typePolicies: {
      Query: {
        fields: {
          product: { merge: true },
          itinerary: { merge: true },
          viewer: { merge: true },
        },
      },
      Itinerary: {
        fields: {
          audits: relayStylePagination(),
        },
      },
    },
  });

let cache;

export const getCache = (): InMemoryCache => {
  if (typeof window === 'undefined') {
    return initializeCache();
  }

  if (!cache) {
    cache = initializeCache();
  }

  return cache;
};
