import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useCallback, useState, useEffect } from 'react';

import {
  GetViewerDocument,
  GetViewerQuery,
  GetViewerQueryVariables,
} from '@generated/graphql';

const useAuth = (
  client: ApolloClient<NormalizedCacheObject>
): GetViewerQuery['viewer']['user'] | null => {
  const [user, setUser] = useState(null);

  const fetchUser = useCallback(async () => {
    try {
      const { data } = await client.query<
        GetViewerQuery,
        GetViewerQueryVariables
      >({
        query: GetViewerDocument,
      });

      const user =
        data?.viewer?.user ??
        (process.env.NODE_ENV === 'development' ? { admin: true } : null);

      if (process.env.NODE_ENV !== 'development') {
        if (!user) {
          window.location.replace(
            `${process.env.NEXT_PUBLIC_LEGACY_APP}/login`
          );
          return;
        }

        if (!user.admin) {
          window.location.replace(process.env.NEXT_PUBLIC_LEGACY_APP);
          return;
        }
      }

      setUser(user);
    } catch (err) {
      if (
        err.message.includes(
          'Not authorized to access TypesViewerQueryType.user'
        )
      ) {
        window.location.replace(`${process.env.NEXT_PUBLIC_LEGACY_APP}/login`);
        return;
      }

      console.error(err);
    }
  }, [client, setUser]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return user;
};

export default useAuth;
