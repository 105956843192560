/**
 * LIBS
 */
import { TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';

type TControlledTextFieldProps<TFormValues> = TextFieldProps & {
  controllerProps: UseControllerProps<TFormValues>;
  changeHandler?: (e: ChangeEvent) => void;
};

function ControlledTextField<TFormValues>({
  controllerProps,
  helperText,
  changeHandler,
  ...restTextFieldProps
}: TControlledTextFieldProps<TFormValues>): ReactElement {
  const {
    field: { onChange, ...restField },
    fieldState: { error },
  } = useController<TFormValues>(controllerProps);

  const wrappedChangeHandler = e => {
    onChange(e);

    if (typeof changeHandler === 'function') {
      changeHandler(e);
    }
  };

  return (
    <TextField
      id={controllerProps.name}
      aria-invalid={error ? 'true' : 'false'}
      helperText={error?.message || helperText}
      error={!!error}
      onChange={wrappedChangeHandler}
      {...restTextFieldProps}
      {...restField}
    />
  );
}

export default ControlledTextField;
