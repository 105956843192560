import styled from '@emotion/styled';
import { CircularProgress, Fab as MuiFab } from '@mui/material';
import { spacing } from '@mui/system';
import { forwardRef } from 'react';

import type { FabProps } from '@mui/material';
import type { RefObject, ReactNode } from 'react';

type TFabWithLoadingProps = FabProps & {
  loading?: boolean;
};

const FabWithLoading = (
  props: TFabWithLoadingProps,
  ref: RefObject<HTMLButtonElement> | null | undefined
): ReactNode => {
  const { loading, children, ...fabProps } = props;

  return (
    <__Fab ref={ref} {...fabProps}>
      {loading ? <CircularProgress size={16} /> : children}
    </__Fab>
  );
};

export default forwardRef<TFabWithLoadingProps>(FabWithLoading);

const __Fab = styled(MuiFab)(spacing);
