import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { Paper } from '@mui/material';

/**
 * COMPONENTS
 */
import TopNavBar from '@components/global/TopNavBar';

/**
 * TYPES
 */
import type { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

const BaseLayout = ({ children }: Props) => (
  <__Root>
    <Global styles={globalStyles} />

    <__AppContent>
      <TopNavBar />

      <__Paper>{children}</__Paper>
    </__AppContent>
  </__Root>
);

export default BaseLayout;

export const getBaseLayout = (page: ReactElement) => (
  <BaseLayout>{page}</BaseLayout>
);

const globalStyles = css`
  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const __AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const __Paper = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.palette.background.default};
  padding: ${p => p.theme.spacing(8)};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

const __Root = styled.main`
  display: flex;
  min-height: 100vh;
`;
