import styled from '@emotion/styled';
import { Grid, IconButton } from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useState, useCallback } from 'react';

/**
 * COMPONENTS
 */
import { getBaseLayout } from '@layouts/BaseLayout';
import SideSearch from '@components/global/SideSearch';

/**
 * TYPES
 */
import type { ReactElement, ReactNode } from 'react';

interface AdminLayoutProps {
  children: ReactElement;
}

const AdminLayout = ({ children }: AdminLayoutProps): ReactElement => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const expandToggleHandler = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} lg={isExpanded ? 3 : 1}>
        <__StickyContainer>
          <IconButton
            aria-label="expand search widget"
            size="small"
            onClick={expandToggleHandler}
          >
            {isExpanded ? (
              <ChevronLeftIcon fontSize="large" />
            ) : (
              <SearchIcon fontSize="large" />
            )}
          </IconButton>
          {isExpanded && <SideSearch />}
        </__StickyContainer>
      </Grid>
      <Grid item xs={12} lg={isExpanded ? 9 : 11}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AdminLayout;

export const getAdminLayout = (page: ReactElement): ReactNode =>
  getBaseLayout(<AdminLayout>{page}</AdminLayout>);

const __StickyContainer = styled.div`
  position: sticky;
  top: 6rem;
`;
