import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';
import type { ReactNode } from 'react';

interface IButtonProps extends SpacingProps {
  component?: ReactNode;
}

const OriginalButton = styled(Button)<IButtonProps>(spacing);

const statusContainedMixin = (theme, status) => {
  const backgroundColor = theme.status[status].base;
  const hoverBackgroundColor = theme.status[status].dark;

  return css`
    color: white;
    background-color: ${backgroundColor};

    &:hover {
      background-color: ${hoverBackgroundColor};
    }
  `;
};

const statusOutlinedMixin = (theme, status) => {
  const color = theme.status[status].dark;
  const borderColor = theme.status[status].dark;

  const hoverBackgroundColor = theme.status[status].light;
  const hoverBorderColor = theme.status[status].darker;

  return css`
    color: ${color};
    border-color: ${borderColor};
    background-color: #fff;

    &:hover {
      background-color: ${hoverBackgroundColor};
      border-color: ${hoverBorderColor};
    }
  `;
};

const statusTextMixin = (theme, status) => {
  const color = theme.status[status].dark;

  const hoverBackgroundColor = theme.status[status].light;

  return css`
    color: ${color};
    border-color: transparent;
    background-color: transparent;

    &:hover {
      background-color: ${hoverBackgroundColor};
    }
  `;
};

export interface IButtonWithStatus {
  $status?: string;
}

const OverrideButton = styled(OriginalButton, {
  shouldForwardProp: p => p !== '$status',
})<IButtonWithStatus>`
  box-shadow: none;

  ${p =>
    p.variant === 'contained' &&
    p.$status &&
    statusContainedMixin(p.theme, p.$status)};
  ${p =>
    p.variant === 'outlined' &&
    p.$status &&
    statusOutlinedMixin(p.theme, p.$status)};
  ${p =>
    p.variant === 'text' && p.$status && statusTextMixin(p.theme, p.$status)};
`;

export default OverrideButton;
