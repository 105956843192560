import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';

type TReturnType = (err: ApolloError) => void;

const useMutationErrorHandler = (): TReturnType => {
  const { enqueueSnackbar } = useSnackbar();

  return (err: ApolloError) => {
    console.error(err);

    enqueueSnackbar(err.message, { variant: 'error' });
  };
};

export default useMutationErrorHandler;
