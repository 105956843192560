import { Box, Card, CardContent, Chip, Typography } from '@mui/material';
import { rgba } from 'polished';
import styled from '@emotion/styled';

type StatsPropsType = {
  title: string;
  amount: string;
  chip?: string;
  percentageText?: string;
  percentagecolor?: string;
};

const Stats = ({
  title,
  amount,
  chip,
  percentageText,
  percentagecolor,
}: StatsPropsType) => (
  <Card>
    <__CardContent>
      <Typography variant="h6" sx={{ marginBottom: 4 }}>
        {title}
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 3 }}>
        <Box fontWeight="fontWeightRegular">{amount}</Box>
      </Typography>

      {percentageText && (
        <__Percentage
          variant="subtitle2"
          sx={{ marginBottom: 4 }}
          color="textSecondary"
          percentagecolor={percentagecolor}
        >
          <span>{percentageText}</span> Since last week
        </__Percentage>
      )}

      {chip && <__Chip label={chip} />}
    </__CardContent>
  </Card>
);

export default Stats;

const __CardContent = styled(CardContent)`
  position: relative;
  margin-top: ${p => p.theme.spacing(3)};

  &:last-child {
    padding-bottom: ${p => p.theme.spacing(4)};
  }
`;

const __Chip = styled(Chip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${p => p.theme.palette.secondary.main};
  color: ${p => p.theme.palette.common.white};
  margin-bottom: ${p => p.theme.spacing(4)};

  span {
    padding-left: ${p => p.theme.spacing(2)};
    padding-right: ${p => p.theme.spacing(2)};
  }
`;

const __Percentage = styled(Typography)<{
  percentagecolor: string;
}>`
  span {
    color: ${p => p.percentagecolor};
    font-weight: bold;
    background: ${p => rgba(p.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${p => p.theme.spacing(2)};
  }
`;
