import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Chip as OriginalChip } from '@mui/material';

export interface IChipWithStatus {
  $status?: string;
}

const statusDefaultMixin = (theme, status) => {
  const backgroundColor = theme.status[status].base;
  const hoverBackgroundColor = theme.status[status].dark;

  return css`
    color: white;
    background-color: ${backgroundColor};

    &:hover {
      background-color: ${hoverBackgroundColor};
    }
  `;
};

const statusOutlinedMixin = (theme, status) => {
  const color = theme.status[status].dark;
  const borderColor = theme.status[status].dark;

  const hoverBackgroundColor = theme.status[status].light;
  const hoverBorderColor = theme.status[status].darker;

  return css`
    color: ${color};
    border-color: ${borderColor};
    background-color: #fff;

    &:hover {
      background-color: ${hoverBackgroundColor};
      border-color: ${hoverBorderColor};
    }
  `;
};

const ChipWithStatus = styled(OriginalChip, {
  shouldForwardProp: p => p !== '$status',
})<IChipWithStatus>`
  ${p =>
    p.$status &&
    (p.variant === 'outlined'
      ? statusOutlinedMixin(p.theme, p.$status)
      : statusDefaultMixin(p.theme, p.$status))};
`;
export default ChipWithStatus;
