import styled from '@emotion/styled';
import { AppBar, Grid, Toolbar } from '@mui/material';
import Link from 'next/link';

import { Button } from '@components/global/ui';

const TopNavBar = () => (
  <__AppBar position="sticky" elevation={0}>
    <Toolbar>
      <Grid container>
        <Grid container item spacing={6} alignItems="center" xs={9}>
          <Grid item>
            <Button component={Link} href="/">
              Home
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} href="/itineraries">
              Sales Overview
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} href="/fulfilment">
              Fulfilment
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} href="/manual-fulfilment">
              Manual Fulfilment
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} href="/calls">
              Calls
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} href="/support-cases">
              Support Cases
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} href="/coupons">
              Coupons
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} href="/itineraries/history">
              History
            </Button>
          </Grid>
        </Grid>

        <Grid container item justifyContent="flex-end" xs={3}>
          <Grid item>
            <Button component={Link} href="/suppliers" className="suppliers">
              Supplier Central
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  </__AppBar>
);

const __AppBar = styled(AppBar)`
  background: #fff;

  .MuiTypography-root {
    color: #000;
    font-size: 0.9rem;
  }

  .suppliers {
    color: #376fd0;
  }
`;

export default TopNavBar;
