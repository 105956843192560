import styled from '@emotion/styled';
import { FormControl as MuiFormControl } from '@mui/material';
import { spacing } from '@mui/system';

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

export default FormControl;
