import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';

export default function createErrorLink() {
  const IS_PROD = process.NODE_ENV === 'production';

  return onError(({ operation, graphQLErrors, networkError }) => {
    const { operationName, variables } = operation;

    if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === 'Not authorized to access TypesViewerQueryType.user') {
          // silenty return from this message
          return;
        }

        Sentry.withScope(scope => {
          scope.setTag('type', 'GraphQLError');
          scope.setTag('operationName', operationName);

          Sentry.captureException(
            // operation name is added here so we don't group all errors together
            new Error(`GraphQL Error - ${operationName}`),
            {
              extra: {
                message,
                operationName,
                variables,
              },
            }
          );
        });

        if (!IS_PROD) {
          console.log(`[GraphQL error] - ${operationName}`);
          console.log('Operation: ', operation);
          console.log('Message: ', message);
          console.log('Location: ', locations);
          console.log('Path: ', path);
        }
      });
    }

    if (networkError) {
      Sentry.withScope(scope => {
        scope.setTag('type', 'GraphQLNetworkError');
        scope.setTag('operationName', operationName);

        Sentry.captureException(
          // operation name is added here so we don't group all errors together
          new Error(`GraphQL Network Error - ${operationName}`),
          {
            extra: {
              networkError,
              operationName,
              variables,
            },
          }
        );
      });

      if (!IS_PROD) {
        console.log(`[GraphQL Network error] - ${operationName}`);
        console.log('Operation: ', operation);
      }
    }
  });
}
