import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';

import type { SpacingProps } from '@mui/system';
import type { ReactElement } from 'react';

type TProps = SpacingProps & {
  text?: string;
};

const CardLoading = ({ text, ...spacingProps }: TProps): ReactElement => (
  <Card {...spacingProps}>
    <CardContent>
      <Grid container sx={{ marginTop: 4, padding: 4 }}>
        <Grid item justifyContent="center" xs={12}>
          <CircularProgress />
        </Grid>

        {text && (
          <Grid item justifyContent="center" xs={12} sx={{ marginTop: 4 }}>
            <Typography variant="h3">{text}</Typography>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
);

export default CardLoading;
