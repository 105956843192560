import { Card, CardContent, Typography } from '@mui/material';

import type { ApolloError } from '@apollo/client';

interface Props {
  error: ApolloError;
  heading: string;
}

const CardError = ({ error, heading }: Props) => (
  <Card>
    <CardContent>
      {heading && <Typography variant="h3">{heading}</Typography>}
      {error}
    </CardContent>
  </Card>
);

export default CardError;
