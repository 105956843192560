// Theme
export const THEME_SET = 'THEME_SET';
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
};

// Auth
export const AUTH_SIGN_IN_REQUEST = 'AUTH_SIGN_IN_REQUEST';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE = 'AUTH_SIGN_IN_FAILURE';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_UP_REQUEST = 'AUTH_SIGN_UP_REQUEST';
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE';
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE';

// Date formats
export const DATE_FORMATS = {
  query: 'yyyy-MM-dd',
  usDateShort: 'MM/dd/yyyy',
  usTimeShort: 'MM/dd/yyyy hh:mm a',
  usDateLong: 'MMM dd yyyy',
  usTimeLong: 'MMM dd yyyy hh:mm a',
  creditCardExpiry: 'MM/yyyy',
  cancellationPolicyDate: 'h a, EEE MMM dd yyyy',
  auDateShort: 'dd-MMM-yyyy',
};
