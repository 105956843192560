import { green, red, yellow, blue } from '@mui/material/colors';

type TColorTheme = {
  light: string;
  base: string;
  dark: string;
  darker: string;
};

export type TStatusColors = {
  danger: TColorTheme;
  success: TColorTheme;
  warning: TColorTheme;
  info: TColorTheme;
};

export type TStatuses = keyof TStatusColors;

declare module '@mui/material/styles/createMuiTheme' {
  interface Theme {
    status: TStatusColors;
  }

  interface DeprecatedThemeOptions {
    status: TStatusColors;
  }
}

const status: TStatusColors = {
  danger: {
    light: red[50],
    base: red[600],
    dark: red[800],
    darker: red[900],
  },
  success: {
    light: green[50],
    base: green[600],
    dark: green[800],
    darker: green[900],
  },
  warning: {
    light: yellow[50],
    base: yellow[700],
    dark: yellow[800],
    darker: yellow[900],
  },
  info: {
    light: blue[50],
    base: blue[600],
    dark: blue[800],
    darker: blue[900],
  },
};

export default status;
