import '@mui/lab/themeAugmentation';

import { THEMES } from '@constants';
import { createTheme as createMuiTheme } from '@mui/material';
import variants from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';
import spTheme from './spTheme';
import status from './status';

export const createTheme = (name: string) => {
  let themeConfig = variants.find(variant => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
      sp: spTheme,
      status: status,
    }
  );
};

const theme = createTheme(THEMES.BLUE);

export default theme;
