import type { UseFormGetValues } from 'react-hook-form';
import type { TFormValues } from './index';

const validateAny = ({
  publicId,
  firstName,
  lastName,
  email,
  phone,
}: TFormValues): boolean | string => {
  if (!publicId && !firstName && !lastName && !email && !phone) {
    return 'At least one required';
  }

  return false;
};

type TValidationResult = true | string;

export const validatePublicId =
  (getValues: UseFormGetValues<TFormValues>) => (): TValidationResult => {
    const formInput = getValues();
    const any = validateAny(formInput);

    if (any) {
      return any;
    }

    return true;
  };

export const validateFirstName =
  (getValues: UseFormGetValues<TFormValues>) => (): TValidationResult => {
    const formInput = getValues();
    const any = validateAny(formInput);

    if (any) {
      return any;
    }

    return true;
  };

export const validateLastName =
  (getValues: UseFormGetValues<TFormValues>) => (): TValidationResult => {
    const formInput = getValues();
    const any = validateAny(formInput);

    if (any) {
      return any;
    }

    return true;
  };

export const validateEmail =
  (getValues: UseFormGetValues<TFormValues>) => (): TValidationResult => {
    const formInput = getValues();
    const any = validateAny(formInput);

    if (any) {
      return any;
    }

    return true;
  };

export const validatePhone =
  (getValues: UseFormGetValues<TFormValues>) => (): TValidationResult => {
    const formInput = getValues();
    const any = validateAny(formInput);

    if (any) {
      return any;
    }

    return true;
  };
