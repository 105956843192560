const spTheme = {
  breakpoints: {
    xxs: {
      min: '384px',
      max: '383.98px',
    },
    xs: {
      min: '576px',
      max: '575.98px',
    },
    sm: {
      min: '768px',
      max: '767.98px',
    },
    md: {
      min: '1024px',
      max: '1023.98px',
    },
    lg: {
      min: '1216px',
      max: '1215.98px',
    },
    xl: {
      min: '1408px',
      max: '1407.98px',
    },
    xxl: {
      min: '1450px',
      max: '1449.98px',
    },
  },
  colours: {
    neutral: {
      n05: '#F9FAFD',
      n10: '#FAFAFA',
      n15: '#F1F3F5',
      n20: '#EDF0F2',
      n30: '#DFE0E1',
      n40: '#C7CED4',
      n50: '#AAAAAA',
      n60: '#8C8C8C',
      n70: '#666666',
      n75: '#8A9BAF',
      n80: '#4C4C4C',
      n85: '#343C54',
      n90: '#383838',
      n95: '#171D35',
      n99: '#222323',
      n100: '#222222',
    },
    red: {
      lightest: '#fef2f4',
      light: '#FAE2E2',
      base: '#e71d36',
      dark: '#BF2600',
    },
    blue: {
      lightest: '#F6F8FF',
      light: '#e4ecff',
      base: '#5080ff',
      dark: '#4066cc',
      fomo: '#00668c',
    },
    green: {
      lightest: '#F6FFFD',
      light: '#C3E8E0',
      base: '#00B187',
      dark: '#27846E',
    },
    yellow: {
      lightest: '#FFFAE6',
      light: '#FDF3CC',
      base: '#FFC400',
      dark: '#FF8B00',
    },
  },
  z: {
    hide: -1,
    base: 0,
    searchWidgetHeroOverlay: 10,
    searchWidget: 20,
    sticky: 1000,
    header: 1100,
    fomo: 1200,
    modal: 1300,
    loading: 1400,
  },
};

export default spTheme;
