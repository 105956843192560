import { Button, CircularProgress } from '@mui/material';

import type { ButtonProps } from '@mui/material';

type Props = ButtonProps & { loading?: boolean };

const getSize = (buttonSize?: 'small' | 'medium' | 'large'): number => {
  if (typeof buttonSize === 'undefined' || buttonSize === 'medium') {
    return 16;
  }

  if (buttonSize === 'small') {
    return 14;
  }

  return 26;
};

const ButtonWithLoading = ({
  children,
  disabled,
  loading,
  size,
  ...buttonProps
}: Props) => (
  <Button disabled={disabled || loading} size={size} {...buttonProps}>
    <CircularProgress
      size={getSize(size)}
      sx={{ display: loading ? 'block' : 'none', marginRight: 2 }}
    />
    {children}
  </Button>
);

export default ButtonWithLoading;
