/**
 * Components
 */
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import { Button, ButtonWithLoading } from '@components/global/ui';

type Props = DialogProps & {
  closeHandler: () => void;
  submitHandler?: () => void;
  contentTitle?: string;
  contentText?: string;
  hideFooter?: boolean;
  formName?: string;
  isFormLoading?: boolean;
  buttonName?: string;
};

const Dialog = ({
  children,
  open,
  closeHandler,
  submitHandler,
  contentTitle,
  contentText,
  hideFooter,
  fullWidth,
  maxWidth,
  formName,
  isFormLoading,
  buttonName,
}: Props) => (
  <MuiDialog
    maxWidth={maxWidth}
    fullWidth={fullWidth}
    open={open}
    onClose={closeHandler}
    scroll="body"
    aria-labelledby="form-dialog-title"
  >
    {contentTitle && (
      <DialogTitle id="form-dialog-title">{contentTitle}</DialogTitle>
    )}

    <DialogContent>
      {contentText && <DialogContentText>{contentText}</DialogContentText>}
      {children}
    </DialogContent>

    {!hideFooter && (
      <DialogActions>
        {formName && (
          <ButtonWithLoading
            color="primary"
            variant="contained"
            type="submit"
            loading={isFormLoading}
            form={formName}
          >
            {buttonName ? buttonName : 'Update'}
          </ButtonWithLoading>
        )}
        <Button onClick={closeHandler} color="primary" variant="outlined">
          Close
        </Button>
        {!!submitHandler && (
          <Button onClick={submitHandler} color="primary">
            Submit
          </Button>
        )}
      </DialogActions>
    )}
  </MuiDialog>
);

export default Dialog;
